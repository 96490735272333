.MyContainer {
  width: 100vw;
  height: 100vh;
  border-width: 0px;
  padding: 0px;
  --bs-gutter-x: 0rem;
}

button:hover {
  cursor: pointer;
}

.group {
  display: flex;
  position: fixed;
  left: 30%;
  width: 100%;
  text-align: center;
  margin-bottom: 10%;
  margin-top: 3%;
}

.groupInput {
  width: 35%;
  padding-top: 1%;
  padding-bottom: 1%;
}


.abc {
  margin-left: 3%;
  margin-right: 1%;
  width: 10%;
  max-width: 40px;
}

.subject {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  padding-top: 5%;
}

.button2 {
  position: relative;
  left: 3%;
  width: 10%;
  max-width: 30px;
}

.link {
  width: 20%;
  font-size: 150%;
}

.main {
  text-align: center;
  margin: 0px;
  width: 100%;
  padding-bottom: 5%;
}

.back {
  position: fixed;
  top: 1%;
  right: 1%;
  width: 26%;
  min-width: 80px;
}

.play {
  display: flex;
  justify-content: flex-end;
}

.play button {
  padding-top: 1%;
  padding-bottom: 1%;
  margin-left: 1%;
}

.pause {
  display: flex;
  justify-content: right;
  margin: 1%;
}

/* .content {  
  width: 100%;
  height: 100%;
  text-align: center;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  padding-top: 3%;
  
} */

.content button {
  margin: 1%;
}

.mm {
  display: flex;
  justify-content: center;
}



.qna {
  display: block;
}

.MyModal {
  width: 55%;
  height: 45%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  border: 1px solid black;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modalSetting {
  width: 55%;
  height: 15%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  border: 1px solid black;
  display: flex;
  visibility: hidden; 
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.xbutton {
  position: fixed;
  width: 100%;
  text-align: right;
  top: 5%;
  margin-right: 10%;
}

.c {
  padding-top: 10%;
}


.subj {
  visibility: visible;
}

.running {
  visibility: hidden;
}

.on-off {
  visibility: visible;
}

.off {
  visibility: hidden;
}

.on {
  visibility: visible;
}

.highlighted {
  display: none;
}